<template>
  <v-card>
    <vue-perfect-scrollbar :style="height">
      <v-card-text>
        <base-alert :fontsize="12">
          承認の最大回数（階層数）および承認依頼通知有無を指定します
        </base-alert>
        <div>
          <p class="fs-12 fw-bold">
            1. 仕訳承認回数の指定
          </p>
          <v-flex xs3 class="my-0">
            <v-autocomplete
              v-model="settings.approve_level"
              class="fs-10 py-0 ml-3"
              prefix="回"
              :items="approveLevelItems"
              reverse
            />
          </v-flex>
        </div>

        <div>
          <p class="fs-12 fw-bold">
            2. 支払確認回数の指定
          </p>
          <v-flex xs3 class="my-0">
            <v-autocomplete
              v-model="settings.pay_confirm_level"
              class="fs-10 py-0 ml-3"
              prefix="回"
              :items="payConfirmLevelItems"
              reverse
            />
          </v-flex>
        </div>

        <div>
          <p class="fs-12 fw-bold">
            3. 承認依頼通知の有無
          </p>
          承認操作や支払確認を行った際に、次の承認者を指定して通知を送信することができます。<br />
          通知が不要な場合は、下記のチェックを外してください。
          <v-checkbox
            v-model="settings.send_approval_request"
            label="承認依頼メールを送信する"
            hide-details
          />
        </div>
      </v-card-text>
    </vue-perfect-scrollbar>
    <v-card-actions>
      <v-btn color="primary" @click="$emit('click:previous')"> 戻る </v-btn>
      <v-spacer />
      <v-btn color="primary" @click="onClickNext"> 次へ </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  props: {
    height: {
      type: String,
      default: '',
    },
    settings: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      approveLevelItems: [1, 2, 3, 4, 5],
      payConfirmLevelItems: [0, 1, 2, 3]
    }
  },
  methods: {
    onClickNext() {
      this.$emit('click:next')
    },
  },
}
</script>
