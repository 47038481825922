<template>
  <v-row class="px-4">
    <v-col cols="2">
      <v-checkbox
        v-model="form.setting[name]"
        label="使用"
        color="blue"
        hide-details
        :readonly="readonly"
        :disabled="disable"
        @click.stop="!readonly ? onClickUsage() : ''"
      />
      <s-icon
        v-if="description"
        :tooltip="description"
        class="tw-pl-1"
        icon="feather-info"
        size="lg"
      />
    </v-col>

    <v-col cols="4">
      <s-text-field
        v-model="form.definition[name]"
        :label="label"
        :disabled="disable"
      />
    </v-col>

    <v-col cols="3">
      <v-radio-group
        v-model="form.journalRules[name].input_debit"
        :disabled="!form.setting[name] || disable"
        class="pa-0 ma-0"
        row
      >
        <v-radio color="blue" label="入力必須" :value="2" />
        <v-radio
          v-show="name !== 'account_title'"
          color="blue"
          label="入力禁止"
          :value="0"
        />
        <v-radio color="blue" label="任意" :value="1" />
      </v-radio-group>
    </v-col>
    <v-col cols="3">
      <v-radio-group
        v-model="form.journalRules[name].input_credit"
        :disabled="!form.setting[name] || disable"
        class="pa-0 ma-0"
        row
      >
        <v-radio color="blue" label="入力必須" :value="2" />
        <v-radio
          v-show="name !== 'account_title'"
          color="blue"
          label="入力禁止"
          :value="0"
        />
        <v-radio color="blue" label="任意" :value="1" />
      </v-radio-group>
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: {
    form: {
      type: Object,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    required: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    disable: {
      type: Boolean,
      default: false,
    },
    description: {
      type: String,
      default: null,
    },
  },
  data() {
    return {}
  },
  mouted() {
    if (this.disable) {
      this.form.setting[name] = 0
    }
  },
  watch: {
    disable: function (value) {
      if (value === true) {
        this.setUsage(this.name, false)
      }
    },
  },
  methods: {
    onClickUsage() {
      const name = this.name
      if (this.form.setting[name] > 0) {
        this.setUsage(name, false)
      } else {
        this.setUsage(name, true)
      }
    },
    setUsage(name, value) {
      if (value) {
        this.form.setting[name] = 1
        this.form.journalRules[name].input_debit = 1
        this.form.journalRules[name].input_credit = 1
      } else {
        this.form.setting[name] = 0
        this.form.journalRules[name].input_debit = -1
        this.form.journalRules[name].input_credit = -1
      }
    },
  },
}
</script>
