<template>
  <v-card>
    <vue-perfect-scrollbar :style="height">
      <v-card-text>
        <base-alert :fontsize="12">
          FBデータ作成時の振込元口座を指定します
        </base-alert>
        <v-form ref="form">
          <div v-for="(userBank, index) in userBankList" :key="index">
            <v-checkbox
              v-show="userBankList.length > 1"
              v-model="userBank.default"
              label="デフォルト口座に設定する"
              dense
              @change="setDefault($event, index)"
            />
            <form-user-bank :user-bank="userBank" />
          </div>
          <div class="fw-bold my-3">
            銀行口座を追加
            <v-btn icon x-small color="primary" @click="addUserBankForm">
              <v-icon>feather-plus</v-icon>
            </v-btn>
            <v-btn
              v-show="userBankList.length > 1"
              class="ml-2"
              color=""
              small
              @click="popUserBankForm"
            >
              削除
            </v-btn>
          </div>
        </v-form>
      </v-card-text>
    </vue-perfect-scrollbar>
    <v-card-actions>
      <v-btn color="primary" @click="$emit('click:previous')"> 戻る </v-btn>
      <v-spacer />
      <v-btn color="primary" @click="onClickNext"> 次へ </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import FormUserBank from 'Views/settings/components/form/FormUserBank.vue'

export default {
  components: {
    FormUserBank,
  },
  props: {
    height: {
      type: String,
      default: '',
    },
    userBankList: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {}
  },
  methods: {
    validate() {
      return this.$refs.form.validate()
    },
    onClickNext() {
      const isValid = this.validate()
      if (isValid) {
        this.$emit('click:next')
      }
    },
    addUserBankForm() {
      this.userBankList.push({
        bank_name: '',
        branch_name: '',
        bank_account_name: '',
        bank_account_type: '',
        bank_account_number: '',
        user_code: '',
        default: false,
      })
    },
    popUserBankForm() {
      this.userBankList.pop()
    },
    setDefault(flag, index) {
      if (flag) {
        for (const [_index, userBank] of this.userBankList.entries()) {
          if (index !== _index) {
            userBank.default = false
          }
        }
      }
    },
  },
}
</script>
