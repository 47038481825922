export function convertPaymentType(type) {
  // 振込:1、引落:2、クレジット:3、手形:4, 現金:5、海外送金:6、その他:0
  const _type = !type ? 0 : Number(type)
  switch (_type) {
    case 1:
      return { content: '振込', color: 'blue', twColor: 'blue-500' }
    case 2:
      return { content: '引落', color: 'green', twColor: 'green-500' }
    case 3:
      return { content: 'ｸﾚｼﾞｯﾄ', color: 'red', twColor: 'red-500' }
    case 4:
      return { content: '手形', color: 'orange', twColor: 'yellow-500' }
    case 5:
      return { content: '現金', color: 'pink', twColor: 'pink-500' }
    case 6:
      return { content: '海外送金', color: 'gray', twColor: 'gray-500' }
    case 0:
      return { content: 'その他', color: 'purple', twColor: 'purple-500' }
  }
}

export function convertPaymentTypeToText(type) {
  try {
    return convertPaymentType(type).content
  } catch (e) {
    return ''
  }
}


export function correctMasterListByName(masterList, name, code) {
  const nameList = masterList.map(obj => obj.name)
  if (!nameList.includes(name) && name) {
      masterList =  [
         ...masterList,
          {
            name: name,
            name_code: `${name} (${code})`
          }
      ]
  }
  return masterList
}