<template>
  <v-card>
    <vue-perfect-scrollbar :style="height">
      <v-card-text>
        <base-alert :fontsize="12">
          請求書データを読み取った際の初期支払サイトを設定できます<br />伝票日付から支払サイトを基準に支払日を自動計算します
        </base-alert>
        <v-layout>
          <v-flex xs4 class="px-3">
            <template>
              <v-autocomplete
                v-model="settings.pay_month"
                :items="pay_month_list"
                label="支払月"
                item-text="name"
                item-value="value"
                hide-details
                clearable
                class="small-input"
                dense
                outlined
                filled
              />
            </template>
          </v-flex>
          <v-flex xs3 class="px-1">
            <template>
              <v-autocomplete
                v-model="settings.pay_day"
                :items="pay_day_list"
                label="日"
                item-text="name"
                item-value="value"
                hide-details
                clearable
                class="small-input"
                dense
                outlined
                filled
              />
            </template>
          </v-flex>
        </v-layout>
      </v-card-text>
    </vue-perfect-scrollbar>
    <v-card-actions>
      <v-btn color="primary" @click="$emit('click:previous')"> 戻る </v-btn>
      <v-spacer />
      <v-btn color="primary" @click="onClickNext"> 次へ </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  props: {
    height: {
      type: String,
      default: '',
    },
    settings: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      pay_month_list: [],
      pay_day_list: [],
    }
  },
  created() {
    this.createPaySiteList()
  },
  methods: {
    onClickNext() {
      this.$emit('click:next')

      // const isValid = this.validate()
      // if (isValid){
      //   this.$emit('click:next')
      // }
    },
    createPaySiteList() {
      try {
        for (let i = 0; i <= 12; i++) {
          if (i == 0) {
            this.pay_month_list.push({ name: '当月', value: i })
          } else {
            this.pay_month_list.push({ name: i + 'ヶ月後', value: i })
          }
        }
        for (let i = 31; i >= 1; i--) {
          if (i == 31) {
            this.pay_day_list.push({ name: '月末', value: i })
          } else {
            this.pay_day_list.push({ name: i + '日', value: i })
          }
        }
      } catch (e) {
        console.log(e)
      }
    },
  },
}
</script>
