<template>
  <v-row class="px-4">
    <v-col cols="2">
      <v-checkbox
        v-model="form.setting[name]"
        label="使用"
        :true-value="!form.setting[name] ? 1 : form.setting[name]"
        :false-value="0"
        color="blue"
        hide-details
        :disabled="disable"
      />
    </v-col>

    <v-col cols="4">
      <s-text-field
        v-model="form.definition[name]"
        :label="label"
        :disabled="disable"
      />
    </v-col>

    <v-col cols="3">
      <v-radio-group
        v-model="form.setting[name]"
        :disabled="!form.setting[name] || disable"
        class="pa-0 ma-0"
        row
      >
        <v-radio color="blue" label="入力必須" :value="2" />
        <v-radio color="blue" label="任意" :value="1" />
      </v-radio-group>
    </v-col>
    <v-col cols="3" />
  </v-row>
</template>

<script>
export default {
  props: {
    form: Object,
    name: null,
    label: null,
    required: false,
    disable: false,
  },
  data() {
    return {}
  },
  mouted() {
    if (this.disable) {
      this.form.setting[name] = 0
    }
  },
  watch: {
    disable: function (value) {
      if (value === true) {
        this.form.setting[this.name] = 0
      }
    },
  },
}
</script>