<template>
  <v-form ref="form">
    <v-layout row wrap px-3 mt-3 class="border-bottom-1">
      <v-flex xs6 class="px-2">
        <v-autocomplete
          :value="userBank.bank_name"
          :items="bankList"
          :readonly="readonly"
          label="金融機関"
          item-text="name_code"
          item-value="name"
          dense
          outlined
          filled
          @change="
            userBank.bank_name = $event
            userBank.bank_branch_name = ''
          "
        />
      </v-flex>
      <v-flex xs6 class="px-2">
        <v-autocomplete
          v-model="userBank.bank_branch_name"
          :items="branchList"
          :readonly="readonly"
          label="支店"
          item-text="name_code"
          item-value="name"
          dense
          outlined
          filled
        />
      </v-flex>
      <v-flex xs6 class="px-2">
        <v-autocomplete
          v-model="userBank.bank_account_type"
          :items="bankAccountTypeList"
          :readonly="readonly"
          label="口座種別"
          item-text="name"
          item-value="name"
          dense
          outlined
          filled
        />
      </v-flex>
      <v-flex xs6 class="px-2">
        <v-text-field
          v-model="userBank.bank_account_number"
          :rules="[rules.accountNumber]"
          :readonly="readonly"
          label="口座番号"
          maxlength="7"
          dense
          outlined
          filled
        />
      </v-flex>
      <v-flex xs6 class="px-2">
        <v-text-field
          v-model="userBank.bank_account_name"
          :rules="[rules.accountNameLength, rules.accountNameCharacters]"
          :readonly="readonly"
          label="口座名義人名（カナ）"
          tabindex
          dense
          outlined
          filled
        />
      </v-flex>
      <v-flex xs6 class="px-2">
        <v-text-field
          v-model="userBank.user_code"
          v-tooltip="'金融機関からの指定がある場合は入力ください'"
          :readonly="readonly"
          label="委託者コード（振込依頼人コード）"
          tabindex
          dense
          outlined
          filled
        />
      </v-flex>
    </v-layout>
  </v-form>
</template>

<script>
import { mapGetters } from 'vuex'
import { correctMasterListByName } from '@/helpers/payment/index'

export default {
  props: {
    userBank: {
      type: Object,
      required: true,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      rules: {
        required: (v) => !!v || this.$t('message.form_input_required'),
        accountNumber: (v) =>
          (v.replace(/^[0-9]*/, '').length == 0 && v.length <= 7) ||
          this.$t('message.bank_account_number_form_message'),
        accountNameLength: (v) =>
          v.length <= 48 ||
          this.$t('message.bank_account_name_form_message_length'),
        accountNameCharacters: (v) =>
          v.replace(/^[ァ-ンｦ-ﾟA-Z0-9ヴ\(\)\.\-\/ー（） 　]+$/, '').length ==
            0 ||
          this.$t('message.bank_account_name_form_message_name_characters'),
      },
    }
  },
  computed: {
    ...mapGetters(['bankAccountTypeList']),
    bankList() {
      const bankName = this.userBank.bank_name ? this.userBank.bank_name : ''
      const bankCode = this.userBank.bank_code ? this.userBank.bank_code : ''
      let bankList = this.$store.getters.bankList
      bankList = correctMasterListByName(bankList, bankName, bankCode)
      return bankList
    },
    branchList() {
      const bankName = this.userBank.bank_name ? this.userBank.bank_name : ''
      let branchList = this.$store.getters.bankBranchList[bankName]
      branchList = branchList ? branchList : []
      const branchName = this.userBank.bank_branch_name
        ? this.userBank.bank_branch_name
        : ''
      const branchCode = this.userBank.bank_branch_code
        ? this.userBank.bank_branch_code
        : ''
      branchList = correctMasterListByName(branchList, branchName, branchCode)
      return branchList
    },
  },
  methods: {
    validate() {
      try {
        return this.$refs.form.validate()
      } catch (e) {
        console.log(e)
      }
    },
  },
}
</script>
