<template>
  <v-card>
    <vue-perfect-scrollbar :style="height">
      <v-card-text>
        <base-alert :fontsize="12">
          費用計上時仕訳および支払時仕訳の利用有無を指定します
        </base-alert>
        <v-form ref="form">
          <template>
            <div>
              仕訳機能を利用しますか？
              <v-radio-group v-model="isUseJournal" :rules="inputRules">
                <v-radio label="はい" :value="true" />
                <v-radio
                  label="いいえ"
                  :value="false"
                  @change="onClickNotUseJournal"
                />
              </v-radio-group>
            </div>
            <div v-show="isUseJournal">
              支払時の消込仕訳をsweeepで登録しますか？
              <v-radio-group
                v-model="isUsePayJournal"
                :rules="isUseJournal ? inputRules : []"
              >
                <v-radio label="はい" :value="true" />
                <v-radio label="いいえ" :value="false" />
              </v-radio-group>
            </div>
            <div v-show="isUseJournal">
              源泉税の仕訳をsweeepで登録しますか？
              <v-radio-group
                v-model="isUseWithholdingTax"
                :rules="isUseJournal ? inputRules : []"
              >
                <v-radio label="はい" :value="true" />
                <v-radio label="いいえ" :value="false" />
              </v-radio-group>
            </div>
            <div>
              振込手数料の先方負担指定機能を利用しますか？
              <v-radio-group v-model="paidByCustomer" :rules="inputRules">
                <v-radio label="はい" :value="true" />
                <v-radio label="いいえ" :value="false" />
              </v-radio-group>
            </div>
            <div>
              外貨入力機能を利用しますか？
              <v-radio-group
                v-model="settingForeignCurrency"
                :rules="inputRules"
              >
                <v-radio
                  label="はい"
                  :value="true"
                />
                <v-radio
                  label="いいえ"
                  :value="false"
                />
              </v-radio-group>
            </div>
          </template>
        </v-form>
      </v-card-text>
    </vue-perfect-scrollbar>
    <v-card-actions>
      <v-spacer />
      <v-btn color="primary" @click="onClickNext"> 次へ </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  props: {
    height: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      isUseJournal: '',
      isUsePayJournal: '',
      isUseWithholdingTax: '',
      paidByCustomer: '',
      settingForeignCurrency: '',
      inputRules: [(v) => v !== '' || this.$t('message.form_input_required')],
      settingJournal: 0,
    }
  },
  methods: {
    validate() {
      return this.$refs.form.validate()
    },
    onClickNext() {
      const isValid = this.validate()
      if (isValid) {
        const setting = {
          settingJournal: this.isUseJournal,
          settingPayJournal:
            this.isUsePayJournal || this.isUseWithholdingTax ? true : false,
          settingPaidByCustomer: this.paidByCustomer,
          settingForeignCurrency: this.settingForeignCurrency,
          isUseWithholdingTax: this.isUseWithholdingTax,
        }
        this.$emit('click:next', setting)
      }
    },
    onClickNotUseJournal() {
      this.isUsePayJournal = ''
      this.isUseWithholdingTax = ''
    },
  },
}
</script>
