<template>
  <v-card>
    <vue-perfect-scrollbar :style="height">
      <v-card-text v-if="!settingJournal">
        設定は不要です。次に進んでください。
      </v-card-text>

      <v-card-text v-if="settingJournal">
        <base-alert :fontsize="12">
          仕訳で使用する管理項目を設定します
        </base-alert>
        <v-checkbox
          v-model="freee.isUse"
          label="freee連携機能を利用する場合は、チェックを入れてください。"
          hide-details
        />
        <v-divider />
        <v-row>
          <v-col md="8" lg="7">
            <v-row class="px-4">
              <v-col cols="6"> 仕訳項目設定 </v-col>
              <v-col cols="3"> 借方 </v-col>
              <v-col cols="3"> 貸方 </v-col>
            </v-row>
            <form-journal-item
              :form="form"
              name="account_title"
              label="勘定科目の表示名"
              :readonly="freee.isUse"
              :description="
                freee.isUse ? 'freeeと同期する場合は必須の項目です。' : ''
              "
            />

            <form-journal-item
              v-show="!freee.isUse"
              :form="form"
              name="sub_account"
              label="補助科目の表示名"
              :disable="form.setting.account_title === 0"
            />

            <form-journal-item
              :form="form"
              name="department"
              label="部門の表示名"
              description="チェックを外した場合でもマスタ編集および権限設定は可能です。"
            />

            <form-journal-item
              :form="form"
              name="vendor"
              label="取引先の表示名"
            />

            <form-journal-item
              v-show="!freee.isUse"
              :form="form"
              name="project"
              label="プロジェクトの表示名"
            />

            <form-journal-item
              :form="form"
              name="segment"
              label="セグメントの表示名"
            />

            <form-journal-item :form="form" name="item" label="品目の表示名" />

            <form-journal-item :form="form" name="tag" label="タグの表示名" />

            <form-journal-item
              :form="form"
              name="walletable"
              label="口座の表示名"
            />

            <form-journal-item
              :form="form"
              name="tax_class"
              label="税区分の表示名"
              :readonly="freee.isUse"
              :description="
                freee.isUse ? 'freeeと同期する場合は必須の項目です。' : ''
              "
            />

            <v-row class="px-4">
              <v-col cols="6"> テキスト項目設定 </v-col>
              <v-col cols="6" />
            </v-row>

            <form-journal-text-setting
              :form="form"
              name="description"
              label="摘要の表示名"
            />

            <form-journal-text-setting
              :form="form"
              name="free_text_1"
              label="備考1の表示名"
            />

            <form-journal-text-setting
              :form="form"
              name="free_text_2"
              label="備考2の表示名"
            />
          </v-col>
        </v-row>
      </v-card-text>
    </vue-perfect-scrollbar>
    <v-card-actions>
      <v-btn color="primary" @click="$emit('click:previous')"> 戻る </v-btn>
      <v-spacer />
      <v-btn color="primary" @click="$emit('click:next')"> 次へ </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import FormJournalItem from 'Views/settings/components/form/FormJournalItem'
import FormJournalTextSetting from 'Views/settings/components/form/FormJournalTextSetting'

export default {
  components: {
    FormJournalItem,
    FormJournalTextSetting,
  },
  props: {
    height: {
      type: String,
      default: '',
    },
    settingJournal: {
      type: Boolean,
      default: true,
    },
    freee: {
      type: Object,
      required: true,
    },
    form: {
      type: Object,
      required: true,
    },
  },
}
</script>
