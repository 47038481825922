<template>
  <div>
    <base-dialog
      ref="freee_setting_dialog"
      title="設定完了"
      :message="message"
      cancel-text="後で設定する"
      submit-text="続ける"
      persistent
      @cancel="onClickCancel"
      @submit="onClickFreeeSetting"
    />
    <base-dialog
      ref="setting_dialog"
      title="設定完了"
      :message="message"
      cancel-text="後で設定する"
      submit-text="続ける"
      persistent
      @cancel="onClickCancel"
      @submit="onClickSetting"
    />
    <v-stepper v-model="e1" alt-labels>
      <v-stepper-header>
        <v-stepper-step :complete="e1 > 1" step="1"> 仕訳 </v-stepper-step>
        <v-divider />
        <v-stepper-step :complete="e1 > 2" step="2"> 管理項目 </v-stepper-step>
        <v-divider />
        <v-stepper-step :complete="e1 > 3" step="3">
          支払サイト
        </v-stepper-step>
        <v-divider />
        <v-stepper-step :complete="e1 > 4" step="4">
          振込元口座
        </v-stepper-step>
        <v-divider />
        <v-stepper-step :complete="e1 > 5" step="5"> 承認 </v-stepper-step>
      </v-stepper-header>

      <v-stepper-items>
        <v-stepper-content step="1">
          <invoice-edit-setting-wizard
            :height="getScrollHeight()"
            @click:next="onChangeSettingJournal($event)"
          />
        </v-stepper-content>
        <v-stepper-content step="2">
          <item-for-journal-wizard
            :height="getScrollHeight()"
            :setting-journal="settingJournal"
            :freee="freee"
            :form="journalForm"
            @click:previous="onClickPrevious"
            @click:next="onClickNext"
          />
        </v-stepper-content>
        <v-stepper-content step="3">
          <payment-terms-setting-wizard
            :height="getScrollHeight()"
            :settings="paymentTermsSettings"
            @click:previous="onClickPrevious"
            @click:next="onClickNext"
          />
        </v-stepper-content>
        <v-stepper-content step="4">
          <user-bank-setting-wizard
            :height="getScrollHeight()"
            :user-bank-list="userBankList"
            @click:previous="onClickPrevious"
            @click:next="onClickNext"
          />
        </v-stepper-content>
        <v-stepper-content step="5">
          <approval-setting-wizard
            :height="getScrollHeight()"
            :settings="approvalSettings"
            @click:previous="onClickPrevious"
            @click:next="submit"
          />
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
  </div>
</template>

<script>
import Snackbar from 'Helpers/snackbar/index'
import InvoiceEditSettingWizard from './wizard/InvoiceEditSettingWizard.vue'
import PaymentTermsSettingWizard from './wizard/PaymentTermsSettingWizard.vue'
import UserBankSettingWizard from './wizard/UserBankSettingWizard.vue'
import ApprovalSettingWizard from './wizard/ApprovalSettingWizard.vue'
import ItemForJournalWizard from './wizard/ItemForJournalWizard.vue'

export default {
  components: {
    InvoiceEditSettingWizard,
    PaymentTermsSettingWizard,
    UserBankSettingWizard,
    ApprovalSettingWizard,
    ItemForJournalWizard,
  },
  data() {
    return {
      e1: 1,
      message: '',
      settingJournal: false,
      settingPayJournal: false,
      settingPaidByCustomer: false,
      settingForeignCurrency: false,
      isUseWithholdingTax: false,
      paymentTermsSettings: {
        payMonth: '',
        payDay: '',
      },
      userBankList: [
        {
          bank_name: '',
          branch_name: '',
          bank_account_name: '',
          bank_account_type: '',
          bank_account_number: '',
          user_code: '',
          default: true,
        },
      ],
      approvalSettings: {
        approve_level: 3,
        pay_confirm_level: 3,
        send_approval_request: true,
      },
      journalForm: {
        setting: {
          account_title: true,
          sub_account: true,
          department: true,
          tax_class: true,
          vendor: true,
          project: true,
          segment: true,
          item: true,
          tag: true,
          walletable: true,
          description: 1,
          free_text_1: false,
          free_text_2: false,
        },
        journalRules: {
          account_title: { input_debit: 1, input_credit: 1 },
          sub_account: { input_debit: 1, input_credit: 1 },
          department: { input_debit: 1, input_credit: 1 },
          tax_class: { input_debit: 1, input_credit: 1 },
          vendor: { input_debit: 1, input_credit: 1 },
          project: { input_debit: 1, input_credit: 1 },
          segment: { input_debit: 1, input_credit: 1 },
          item: { input_debit: 1, input_credit: 1 },
          tag: { input_debit: 1, input_credit: 1 },
          walletable: { input_debit: 1, input_credit: 1 },
        },
        definition: {
          account_title: '勘定科目',
          sub_account: '補助科目',
          department: '部門',
          tax_class: '税区分',
          vendor: '取引先',
          project: 'プロジェクト',
          segment: 'セグメント',
          item: '品目',
          tag: 'タグ',
          walletable: '口座',
          free_text_1: '備考1',
          free_text_2: '備考2',
          description: '摘要',
        },
      },
      freee: {
        isUse: false,
      },
    }
  },
  watch: {
    'freee.isUse': {
      handler(val) {
        if (val) {
          this.journalForm.setting.sub_account = false
          this.journalForm.setting.project = false
        } else {
          this.journalForm.setting.sub_account = true
          this.journalForm.setting.project = true
        }
      },
      immediate: true,
    },
  },
  mounted() {
    this.$store.commit('initialiseStore')
    this.$store.dispatch('getBankData')
  },
  methods: {
    getScrollHeight() {
      return 'height:calc(100vh - 190px)'
    },
    onClickNext() {
      this.e1 += 1
    },
    onClickPrevious() {
      this.e1 -= 1
    },
    onChangeSettingJournal(payload) {
      this.settingJournal = payload.settingJournal
      this.settingPayJournal = payload.settingPayJournal
      this.settingPaidByCustomer = payload.settingPaidByCustomer
      this.settingForeignCurrency = payload.settingForeignCurrency
      this.isUseWithholdingTax = payload.isUseWithholdingTax
      this.onClickNext()
    },
    getJournalRulesForRequest() {
      const checkVal = (v) => {
        return v === -1 ? 0 : v
      }
      const rules = {}
      const rulesForm = this.journalForm.journalRules
      Object.keys(this.journalForm.journalRules).forEach((name) => {
        // -1 => 0 に変換(サーバーは0~2のみを受け付ける)
        rules[name] = {
          input_credit: checkVal(rulesForm[name].input_credit),
          input_debit: checkVal(rulesForm[name].input_debit),
        }
      })
      return rules
    },
    submit() {
      const payload = {
        setting_journal: this.settingJournal,
        setting_pay_journal: this.settingPayJournal,
        setting_paid_by_customer: this.settingPaidByCustomer,
        setting_foreign_currency: this.settingForeignCurrency,
        approve_level: this.approvalSettings.approve_level,
        pay_confirm_level: this.approvalSettings.pay_confirm_level,
        send_approval_request: this.approvalSettings.send_approval_request,
        setting: this.journalForm.setting,
        definition: this.journalForm.definition,
        journal_rules: this.getJournalRulesForRequest(),
        setting_pay_day: this.paymentTermsSettings.pay_day,
        setting_pay_month: this.paymentTermsSettings.pay_month,
        user_bank_list: this.userBankList,
      }
      this.$store
        .dispatch('postInitialSettings', payload)
        .then(() => {
          this.openDialg()
        })
        .catch((err) => {
          Snackbar.error(err.message)
        })
    },
    openDialg() {
      if (this.freee.isUse) {
        if (this.isUseWithholdingTax) {
          this.message =
            'freee連携設定を行います。\n連携後、預り源泉税のデフォルト仕訳の設定が必要です。\n(管理者設定メニュー > 基本設定 > デフォルト仕訳／支払　から行えます。)'
        } else {
          this.message = 'freee連携設定を行います。'
        }
        this.$refs.freee_setting_dialog.open()
      } else {
        if (this.settingPayJournal) {
          this.message =
            '各種マスタの設定を行います。\nマスタ設定後、支払時のデフォルト仕訳設定が必要です。\n(管理者設定メニュー > 基本設定 > デフォルト仕訳／支払)'
        } else {
          this.message = '各種マスタの設定を行います。'
        }
        this.$refs.setting_dialog.open()
      }
    },
    onClickCancel() {
      window.location.href = '/'
    },
    onClickFreeeSetting() {
      window.location.href = '/freee'
    },
    onClickSetting() {
      window.location.href = '/master'
    },
  },
}
</script>
